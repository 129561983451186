"use client"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog'
import { cn } from '@/lib/utils'
import { Loader2, Upload } from 'lucide-react'
import Papa from 'papaparse'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { isAddress } from 'viem'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { useToast } from './ui/use-toast'

interface CSVRow {
    [key: string]: string
}

function AddWalletModal({ handleAddWallet, children, setAddWalletModalOpen }: { handleAddWallet: (wallet: string) => void, children: React.ReactNode, setAddWalletModalOpen: (open: boolean) => void }) {
    const { toast } = useToast()
    const [wallet, setWallet] = useState<string>('')
    const [loading, setLoading] = useState(false)

    const onDrop = async (acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        if (file) {
            const text = await file.text()
            Papa.parse<CSVRow>(text, {
                header: true,
                skipEmptyLines: true,
                complete: async (results) => {
                    setLoading(true)
                    const wallets = results.data.map(row => Object.values(row)[0])
                    const invalidWallets: string[] = []

                    for (const wallet of wallets) {
                        if (!isAddress(wallet)) {
                            invalidWallets.push(wallet)
                            continue
                        }
                        await handleAddWallet(wallet)
                    }

                    if (invalidWallets.length > 0) {
                        toast({
                            title: "Some wallets were invalid",
                            variant: "destructive",
                            description: `${invalidWallets.length} wallet(s) were skipped due to invalid format`,
                        })
                    }

                    toast({
                        title: "Success",
                        description: `Added ${wallets.length - invalidWallets.length} wallet(s) to allowlist`,
                    })
                    setLoading(false)
                },
                error: () => {
                    toast({
                        title: "Error",
                        variant: "destructive",
                        description: "Failed to parse CSV file",
                    })
                    setLoading(false)
                }
            })
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv']
        },
        maxFiles: 1
    })

    const handleSubmit = () => {
        if (!isAddress(wallet)) {
            toast({
                title: "Error",
                variant: "destructive",
                description: "That's not a valid wallet address.",
            })
            return
        }
        try {
            setLoading(true)
            handleAddWallet(wallet)
            setTimeout(() => {
                setWallet('')
                setLoading(false)
            }, 1000)
        } catch (error) {
            toast({
                title: "Error",
                variant: "destructive",
                description: "Something went wrong.",
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Dialog>
                <DialogTrigger asChild>
                    {children}
                </DialogTrigger>
                <DialogContent className="sm:max-w-[625px]">
                    <DialogHeader>
                        <DialogTitle>Add Wallet</DialogTitle>
                        <DialogDescription>
                            Add a new wallet address or upload a CSV file with wallet addresses.
                        </DialogDescription>
                    </DialogHeader>

                    <div {...getRootProps()} className={cn(
                        "border-2 border-dashed rounded-lg p-6 cursor-pointer transition-colors",
                        isDragActive ? "border-primary bg-primary/10" : "border-border",
                        loading && "pointer-events-none opacity-50"
                    )}>
                        <input {...getInputProps()} />
                        <div className="flex flex-col items-center justify-center gap-2 text-sm">
                            <Upload className="h-8 w-8 text-muted-foreground" />
                            <p className="text-muted-foreground">Drag & drop a CSV file here, or click to select</p>
                            <p className="text-xs text-muted-foreground">Your CSV should have a header row and wallet addresses in a single column</p>
                        </div>
                    </div>

                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <span className="w-full border-t" />
                        </div>
                        <div className="relative flex justify-center text-xs uppercase">
                            <span className="bg-background px-2 text-muted-foreground">Or</span>
                        </div>
                    </div>

                    <form onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }} className='mt-2'>
                        <span className="text-xs mb-1">Single Wallet Address</span>
                        <Input
                            placeholder="Enter wallet address"
                            value={wallet}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWallet(event.target.value)}
                            disabled={loading}
                        />
                        <Button type="submit" className="mt-4 text-white" disabled={loading}>
                            {loading ? <Loader2 className="animate-spin" /> : 'Add'}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddWalletModal


