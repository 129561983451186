import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { Tournament } from "./schema";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const WSS_API_URL = process.env.NEXT_PUBLIC_WSS_API_ENDPOINT

export type BalanceData = {
  balance: number
  balanceMin: number
}

export type CreditData = {
  data: BalanceData
}

interface ConversionResponse {
  credits: number;
  pricePerToken: number
}

export const userIdToBase64 = (userId: string) => {
  return Buffer.from(userId).toString('base64')
}

export async function getConversionData(tokenA: string | undefined, amount: string): Promise<ConversionResponse> {
  console.log(`Getting conversion data for ${tokenA}`);
  if (!tokenA) return { credits: 0, pricePerToken: 0 };
  // No need for this as its on the backend
  // TODO remove
  const tokenB = "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913" // USDC on Base
  const url = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/conversion?tokena=${tokenA}&tokenb=${tokenB}&amount=${amount}`;
  console.log(`Conversion data url: ${url}`);
  const res = await fetch(url, {
    method: 'GET',
    next: { revalidate: 360 },
    headers: {}
  });

  const response = await res.json();
  return response.data
}

export const logos = [
  { name: "Metacade", url: "https://api.metafuse.me/assets/metacade/metacade.webp" },
  { name: "Based Cartel", url: "https://api.metafuse.me/assets/metacade/game-assets/based_cartel.jpg" },
  { name: "Baby Neiro", url: "https://api.metafuse.me/assets/metacade/game-assets/babyneiro.jpg" },
  { name: "Catalorian", url: "https://api.metafuse.me/assets/metacade/game-assets/catalorian.jpg" },
  { name: "Brett", url: "https://api.metafuse.me/assets/metacade/game-assets/brett.png" },
  { name: "Blu", url: "https://api.metafuse.me/assets/metacade/game-assets/Blu.webp" },
  { name: "Chuck", url: "https://api.metafuse.me/assets/metacade/game-assets/Chuck.webp" },
  { name: "Bolide", url: "https://api.metafuse.me/assets/metacade/game-assets/Bolide.webp" },
  { name: "Geko Base", url: "https://api.metafuse.me/assets/metacade/game-assets/Geko.webp" },
  { name: "DONNIE", url: "https://api.metafuse.me/assets/metacade/game-assets/DONNIE.webp" },
  { name: "SKI MASK DOG", url: "https://api.metafuse.me/assets/metacade/game-assets/Ski.webp" },
  { name: "Toshi", url: "https://api.metafuse.me/assets/metacade/game-assets/Toshi.webp" }
]

export function groupTournamentsByGameId(tournaments: Tournament[]): Record<string, Record<string, Tournament[]>> {
  return tournaments.reduce((acc, tournament) => {
    const category = tournament.createdByUsername === "Metacade" ? "Metacade Tournaments" : "Community Tournaments";

    if (!acc[category]) {
      acc[category] = {};
    }

    const gameKey = `${tournament.gameId}_${tournament.tournamentId}`;

    if (!acc[category][gameKey]) {
      acc[category][gameKey] = [];
    }

    // Add the current tournament to the correct category and gameId array
    acc[category][gameKey].push(tournament);

    return acc;
  }, {} as Record<string, Record<string, Tournament[]>>);
}

export function truncateWalletAddress(address: string, startLength: number = 6, endLength: number = 4): string {
  try {
    return `${address.slice(0, startLength)}...${address.slice(-endLength)}`;
  } catch (error) {
    console.error(error)
    return address
  }
}

export function formatNumber(number: string, decimals: number = 18): string {
  const stringToNumber = parseFloat(number) / Math.pow(10, decimals);
  if (stringToNumber < 1000) {
    return stringToNumber.toFixed(2).replace(/\.00$/, '');
  } else if (stringToNumber < 1000000) {
    return (stringToNumber / 1000).toFixed(1).replace(/\.0$/, '') + 'k'; // Convert to 'k' for thousands
  } else if (stringToNumber < 1000000000) {
    return (stringToNumber / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; // Convert to 'M' for millions
  } else {
    return (stringToNumber / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'; // Convert to 'B' for billions
  }
}

export function formatReadableDate(isoString: string): string {
  const date = new Date(isoString);
  return date.toLocaleString(undefined, {
    month: 'long', // full name of the month
    day: '2-digit', // two-digit day
    year: 'numeric', // four-digit year
    hour: 'numeric', // the hour
    minute: '2-digit', // two-digit minute
    hour12: true // use 12-hour format
  });
}


export function formatUpperCaseString(value: string): string {
  // Split the string by underscores, then transform each word:
  // - Capitalize the first letter
  // - Convert the rest of the word to lowercase
  // Finally, join the words with spaces
  return value
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}