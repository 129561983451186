import { Tournament } from '@/lib/schema';
import { useEffect, useState } from 'react';
import TournamentGameCard from './TournamentGameCard';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from './ui/carousel';

function TournamentCarousel({ tournaments, tournamentId }: { tournaments: Tournament[], tournamentId: number | null }) {
    const [scrollPosition, setScrollPosition] = useState(0);
    let activeTournaments = tournaments.filter((tournament) => tournament.status === 'ACTIVE')

    if (activeTournaments.length === 0) {
        activeTournaments = tournaments
    }

    activeTournaments.sort((a, b) => {
        if (a.primaryColor && !b.primaryColor) return -1;
        if (!a.primaryColor && b.primaryColor) return 1;
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    useEffect(() => {
        const indexOfSelectedTournament = activeTournaments.findIndex((tournament) => tournament.tournamentId == tournamentId);
        let newScrollPosition = indexOfSelectedTournament;
        console.log(newScrollPosition)

        // Adjust scroll position based on screen size
        if (window.innerWidth < 640) { // Mobile view
            newScrollPosition = indexOfSelectedTournament;
        } else { // Tablet and desktop view
            if (indexOfSelectedTournament > 1 && indexOfSelectedTournament < activeTournaments.length - 2) {
                newScrollPosition = indexOfSelectedTournament - 1;
            } else if (indexOfSelectedTournament >= activeTournaments.length - 2) {
                newScrollPosition = activeTournaments.length - 4;
            }
        }

        setScrollPosition(Math.max(0, newScrollPosition));
    }, [activeTournaments, tournamentId]);

    return (
        <Carousel
            opts={{
                align: "start",
                startIndex: scrollPosition,
            }}
            className="col-span-6 px-4 sm:px-0" // Add padding for small screens
        >
            <CarouselContent className="-ml-2 md:-ml-4">
                {activeTournaments.map((tournament) => (
                    <CarouselItem
                        key={tournament.id}
                        className="pl-2 md:pl-4 basis-full sm:basis-1/2 xl:basis-1/4 2xl:basis-1/4"
                    >
                        <TournamentGameCard
                            logoUrl={tournament.createdByUserImage || 'https://api.metafuse.me/assets/metacade/metacade.webp'}
                            primaryColor={tournament.primaryColor || ''}
                            secondaryColor={tournament.secondaryColor || ''}
                            tournament={tournament}
                            activeTournaments={activeTournaments}
                            selected={tournament.tournamentId == tournamentId}
                        />
                    </CarouselItem>
                ))}
            </CarouselContent>
            <div className="relative grid grid-cols-3 sm:grid-cols-6 w-full h-[100px] py-4">
                <div className="col-span-1 p-2 flex justify-start">
                    <CarouselPrevious className="card-border rounded-none bg-none left-[1rem] sm:left-0" />
                </div>
                <div className="col-span-1 sm:col-span-4 p-2 flex items-center justify-center mx-auto">
                    <h3 className="font-bold text-center">{activeTournaments.length} Tournaments</h3>
                </div>
                <div className="col-span-1 p-2 flex justify-end">
                    <CarouselNext className="card-border rounded-none bg-none right-[1rem] sm:right-0" />
                </div>
            </div>
        </Carousel>
    )
}

export default TournamentCarousel;
