"use client"

import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Buy } from '@coinbase/onchainkit/buy';
import '@coinbase/onchainkit/styles.css';
import type { Token } from '@coinbase/onchainkit/token';
import { useState } from 'react';

export function BuyMetacade() {
    const metacadeToken: Token = {
        name: 'Metacade',
        address: '0xc48823ec67720a04a9dfd8c7d109b2c3d6622094',
        symbol: 'MCADE',
        decimals: 18,
        image: 'https://api.metafuse.me/assets/metacade/metacade.webp',
        chainId: 8453,
    };

    return (
        <Buy toToken={metacadeToken} isSponsored={true} className='w-full bg-black text-primary opacity-100 ' />
    );
}

function BuyMetacadeButton() {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    return (
        <>
            <Dialog open={isOpen} onOpenChange={handleOpenChange}>
                <DialogTrigger asChild>
                    <Button
                        variant="outline"
                        className="relative hud-btn w-4/6 border-pink-600 shadow-inner hover:bg-pink-600 text-pink-600 text-bold px-4 py-2 flex items-center space-x-2 my-2 transition-transform duration-300 hover:scale-110"
                        onClick={() => setIsOpen(true)}
                    >
                        Get MCADE
                    </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Metacade Swap Interface</DialogTitle>
                        <DialogDescription>
                            Exchange tokens with a single click.
                        </DialogDescription>
                    </DialogHeader>
                    <h3 className="text-center">Onramp funds</h3>
                    <BuyMetacade />
                    <h3 className="text-center">OR</h3>
                    <div className="grid gap-4 ">
                        <Button className='text-black font-bold text-sm' onClick={() => {
                            window.open(`https://app.uniswap.org/swap?chain=base&outputCurrency=0xc48823ec67720a04a9dfd8c7d109b2c3d6622094&inputCurrency=ETH`, '_blank', 'noreferrer')
                        }}>via Uniswap</Button>
                    </div>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setIsOpen(false)}>Close</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default BuyMetacadeButton
